import { isType } from './typecheck'

/**
 * Create articles structure.
 */
export function createArticles(items: any) {
    let articles: {
        link: string
        category: string
        title: string
        text: string
        image: string
    }[] = []
    items.map(item => {
        let contentItem: any = {}
        contentItem.link = `/${item.nodes[0].table.toLowerCase()}/${item.nodes[0].data.Slug}`
        contentItem.category = item.nodes[0].table === 'Projekte' ? 'Projekt' : 'Artikel'
        item.nodes.map(textItem => {
            switch (true) {
                case isType(textItem.data.Type, 'H1'):
                    contentItem.title = textItem.data.Content
                case isType(textItem.data.Type, 'Teasertext'):
                    contentItem.text = textItem.data.Content
                case isType(textItem.data.Type, 'Titelbild'):
                    contentItem.image = textItem.data.Attachments?.[0]?.src
            }
        })
        articles.push(contentItem)
    })
    return articles
}