import React from 'react'
import styled from 'styled-components'

import ArticlePreview from '../simple/articlePreview'

export default function MagazineArticles({ articles }) {
    return (
        <MagazineArticlesContainer>
            <div className="articles-grid">
                {articles.map((article, index) => {
                    return <ArticlePreview key={index} article={article} />
                })}
            </div>
        </MagazineArticlesContainer>
    )
}

const MagazineArticlesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(25, 1fr);

    .articles-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem 3rem;
        grid-column: 3 / span 22;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / span 17;
            grid-template-columns: 1fr 1fr;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / span 17;
        }

        @media (min-width: ${(props) => props.theme.xl}) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        a.article-preview {
            display: block;
            color: ${(props) => props.theme.colorWhite};
            line-height: 1.5;

            &:hover {
                text-decoration: none;
            }

            img {
                height: 50vw;
                width: 100%;
                object-fit: cover;

                @media (min-width: ${(props) => props.theme.md}) {
                    height: 10rem;
                }
            }

            .preview-category {
                font-size: 0.777rem;
                font-family: 'SawtonBauhaus';
                margin-top: 0.5rem;
            }

            h3 {
                padding-top: 0;
                font-family: 'SawtonBauhaus';
                margin-bottom: 0.5rem;
                font-size: 1.222rem;
                line-height: 1.2;
            }

            p {
                font-size: 0.888rem;
            }
        }
    }
`
