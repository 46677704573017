import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import MagazineArticles from '../components/sections/magazineArticles'
import Contact from '../components/sections/contact'
import { createArticles } from '../utils/createArticles'
import { isType } from '../utils/typecheck'

export const query = graphql`
    query GetMagazineContent {
        magazineSlugs: allAirtable(
            filter: { table: { eq: "Magazin" }, data: { Layout: { eq: "C" } } }
            sort: { order: ASC, fields: rowIndex }
        ) {
            nodes {
                data {
                    Slug
                }
            }
        }
        allEntries: allAirtable(
            filter: { table: { in: ["Projekte", "Artikel"] } }
            sort: { order: ASC, fields: rowIndex }
        ) {
            group(field: data___Slug) {
                nodes {
                    table
                    data {
                        Type
                        Content
                        Attachments {
                            src
                        }
                        Slug
                    }
                }
            }
        }
        magazineContent: allAirtable(
            filter: {
                table: { eq: "Magazin" }
                data: {
                    Wird_Formula_aus_Layout_und_Magazin: { regex: "/Magazin/" }
                }
            }
            sort: { order: ASC, fields: rowIndex }
        ) {
            nodes {
                data {
                    Wird_Formula_aus_Layout_und_Magazin
                    Content
                    Type
                }
            }
        }
    }
`

export default function MagazinPage({ data }) {
    const magazineContent = data.magazineContent.nodes
    let title =
        'Unser Magazin: Projekte und Artikel, als Lösungen und Zusammenhänge — der Einblick in unsere Arbeit'
    let kicker = 'Magazin'

    for (const it of magazineContent) {
        switch (true) {
            case isType(it.data.Type, 'H1'):
                title = it.data.Content
                break

            case isType(it.data.Type, 'Kolumne'):
                kicker = it.data.Content
        }
    }

    let slugs: string[] = []
    const magazineSlugs = data.magazineSlugs.nodes
    for (const it of magazineSlugs) {
        slugs.push(it.data.Slug[0])
    }

    const allEntries = data.allEntries.group
    let entries: any[] = []

    for (const slug of slugs) {
        for (const entry of allEntries) {
            if (slug === entry.nodes[0].data.Slug[0]) {
                entries.push(entry)
                break
            }
        }
    }

    let content = createArticles(entries)

    return (
        <ContentContainer>
            <Layout>
                <span className="kicker">{kicker}</span>
                <h1>{title}</h1>
                <div className="magazin-links">
                    <Link to="/magazin" className="current">
                        Alles anzeigen
                    </Link>
                    <Link to="/projekte">Projekte</Link>
                    <Link to="/artikel">Artikel</Link>
                </div>
                <hr />
                <MagazineArticles articles={content} />
                <Contact />
            </Layout>
        </ContentContainer>
    )
}

const ContentContainer = styled.div`
    hr {
        grid-column: 3 / span 22;
        opacity: 0.75;
        width: 100%;
        color: inherit;
        margin-bottom: 3rem;

        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -5;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -4;
        }
    }

    .magazin-links {
        display: flex;
        grid-column: 3 / -4;
        padding: 1rem 0 2rem;
        font-family: 'SawtonBauhaus';
        font-weight: 300;
        font-size: 1.222rem;
        @media (min-width: ${(props) => props.theme.md}) {
            grid-column: 5 / -5;
        }

        @media (min-width: ${(props) => props.theme.lg}) {
            grid-column: 6 / -4;
        }
        a {
            margin-right: 3rem;
            &:not(.current) {
                opacity: 0.3;
            }
        }
    }
`
